import React ,{ useEffect, useState } from 'react';
import axios from 'axios'
import dayjs from 'dayjs';
import { API,isValidUrl,formatDateToYYYYMMDD,POWER_USER, isValidEmail, SERVICE_ID } from '../utils/Constants';
import  SingleSearchForm from './SingleSearchForm';
import { useAuth0 } from '@auth0/auth0-react';
import { Stack,Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Box, Divider } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers'
import 'dayjs/locale/ja';
import { useLocation } from 'react-router-dom';
import SelectConfirm from './SelectConfirm';
import UpdateDeviceContractConfirm from './UpdateDeviceContractConfirm';

export  function OrgDetail(props) {
  const location = useLocation();
  const { user } = useAuth0();
  const [org, setOrg] = useState(null);
  const [unregister, setUnregister] = useState(false);
  const [rollback, setRollback] = useState(false);
  const [createBilling, setCreateBilling] = useState(false);
  const [changeableNumberOfLaaP, setChangeableLaaP] = useState(false);
  const [orgId, setOrgId] = useState(location.state ? location.state.org_id : '');
  const [information, setInformation] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [stripeEmail, setStripeEmail] = useState("");
  const [openUnregsiterDialog, setOpenUnregisterDialog] = useState(false);
  const [openRollbackDialog, setOpenRollbackDialog] = useState(false);
  const [openBillingDialog, setOpenBillingDialog] = useState(false);
  const VIEW_OPTIONS={"0":"表示","1":"非表示"}

  const isAllowedOperation = v => POWER_USER.includes(v)
  dayjs.locale('ja')

  const handleChange = event => {
    setOrgId(event.target.value)
    setOrg(null)
    setErrorMessage(null)
    setInformation(null)
  }

  useEffect(() => {
    console.log("useEffect called org_id=",orgId)
    if (orgId) {
      // orgIdが設定されている場合は無条件にAPIを呼び出す
      console.log("getOrgInfo called ==== ",orgId)
      getOrgInfo(orgId)
    }
  }, [orgId])

  const handleChangeReason = event => {
    org.reason = (event.target.value);
  }

  const handleChangeURL = event => {
    org.url = (event.target.value);
  }

  const handleChangeUnregDate = v => {
    org.unreg_date = (v);
  }

  const handleChangeDeleteDate = v => {
    org.delete_date = (v);
  }

  const callbackChangeVisible = () => {
    getOrgInfo(org.org_id)
    setInformation("表示変更が完了しました。")
  }

  const callbackChangeContract = (normal,message) => {
    if(normal){
      getOrgInfo(org.org_id)
      setErrorMessage(null)
      setInformation(message)
      return
    }
    setErrorMessage(message)
  }

  const getOrgInfo =  (org_id) => {
    console.log('Search for org_id:', org_id);
    axios.get(API.ENDPOINT+'/v1/org/id/'+org_id,
    { headers: {'Content-Type': 'text/plain', 'x-api-key':API.X_API_KEY}, responseType: 'json' })
    .then(res => {
      setOrg(res.data)
      setErrorMessage(null)
      setUnregister(isAllowedOperation(user.email) && res.data.unreg_flag === 0 && !res.data.unreg_scheduled_date)
      setRollback(isAllowedOperation(user.email) && ((res.data.unreg_flag === 1 && res.data.delete_scheduled_date) || (res.data.unreg_flag === 0 && res.data.unreg_scheduled_date)))
      setCreateBilling(isAllowedOperation(user.email) && !res.data.airtable_id)
      setChangeableLaaP(isAllowedOperation(user.email) && res.data.unreg_flag === 0 && !res.data.unreg_scheduled_date)
    }).catch(err => {
      setOrg(null)
      setErrorMessage("該当データが存在しません。")
    })
  };

  const validationUnregister = () =>{
    if(!org.reason || org.reason.length ===0){
      setErrorMessage("解約理由を入力してください。")
      return false
    }
    if(!org.unreg_date || org.unreg_date.length===0){
      setErrorMessage("最終利用日を入力してください。")
      return false
    }
    if(!org.delete_date || org.delete_date.length===0){
      setErrorMessage("最終保存日を入力してください。")
      return false
    }
    if(org.url && !isValidUrl(org.url)){
      setErrorMessage("URLが不正です。")
      return false
    }
    setErrorMessage("")
    return true;
  }

  const validationRollbackUnregister = () =>{
    if(!org.reason || org.reason.length ===0){
      setErrorMessage("解約(取消)理由を入力してください。")
      return false
    }
    if(org.url && !isValidUrl(org.url)){
      setErrorMessage("URLが不正です。")
      return false
    }
    setErrorMessage("")
    return true;
  }

  const unregsiterLaaP = event => {
    const postdata = {
      "org_id": org.org_id,
      "operator": user.name,
      "reason": org.reason,
      "url": org.url ?? '',
      "firstCancelDate": formatDateToYYYYMMDD(org.unreg_date),
      "finalCancelDate": formatDateToYYYYMMDD(org.delete_date),
    }
    axios.post(API.ENDPOINT+'/v1/laap/schedule'
    ,postdata
    ,{ headers: {'Content-Type': 'text/plain', 'x-api-key':API.X_API_KEY}, responseType: 'json' })
    .then(res => {
      closeDialog();
      setInformation("解約処理が予定されました。")
      getOrgInfo(org.org_id)
      setUnregister(false)
    }).catch(err => {
      closeDialog();
      setErrorMessage(err.response.data)
    })

  };


  const rollbackUnregister = event => {
    const postdata = {
      "org_id": org.org_id,
      "operator": user.name,
      "reason": org.reason,
      "url": org.url ?? '',
      "rollback": true
    }
    //予約済の場合は、予約取消、そうでない場合は解約取消
    if(org.unreg_scheduled_date && org.del_flag === 0){
      axios.post(API.ENDPOINT+'/v1/laap/discardcancel'
        ,postdata
        ,{ headers: {'Content-Type': 'text/plain', 'x-api-key':API.X_API_KEY}, responseType: 'json' })
        .then(res => {
          closeDialog();
          setInformation("解約取消処理が実行されました。")
          getOrgInfo(org.org_id)
          setRollback(false)
        }).catch(err => {
          closeDialog();
          setErrorMessage(err.response.data)
        })
    }else{
      axios.post(API.ENDPOINT+'/v1/laap/cancel1st'
        ,postdata
        ,{ headers: {'Content-Type': 'text/plain', 'x-api-key':API.X_API_KEY}, responseType: 'json' })
        .then(res => {
          closeDialog();
          setInformation("解約取消処理が実行されました。")
          getOrgInfo(org.org_id)
          setRollback(false)
        }).catch(err => {
          closeDialog();
          setErrorMessage(err.response.data)
        })
    }

  };

  const addAirtable = () => {
    const postdata = {
      "org_id": org.org_id,
      "operator": user.name,
      "email": stripeEmail,
    }
    if(stripeEmail.length >0 && !isValidEmail(stripeEmail)){
      setErrorMessage("emailフォーマットが不正です。")
      return;
    }
    axios.post(API.ENDPOINT + '/v1/org/billing'
      , postdata,
      { headers: { 'Content-Type': 'text/plain', 'x-api-key': API.X_API_KEY }, responseType: 'json' })
      .then(res => {
        console.log(res.data)
        getOrgInfo(org.org_id);
        org.stripeEmail = res.data.email
        org.stripe_customer_id = res.data.stripe_customer_id
        org.airtable_id = res.data.airtable_id
        closeDialog()
        setInformation("請求連携を実施しました。")
      }).catch(error => {
        console.log(JSON.stringify(error))
        setErrorMessage(error.response.data["error_message"])
      });
  }

  const closeDialog = () =>  {
    setOpenUnregisterDialog(false)
    setOpenRollbackDialog(false);
    setOpenBillingDialog(false)
  }

  const unregisterButton = () => {
    if(validationUnregister()){
      setOpenUnregisterDialog(true)
    }
  }

  const rollbackUnregisterButton = () => {
    if(validationRollbackUnregister()){
      setOpenRollbackDialog(true)
    }
  }

  const emailChange = event => {
    setStripeEmail(event.target.value);
    setErrorMessage("")
  };

  const addAirtableButton = () => {
    setOpenBillingDialog(true)
    return
  }

  return (
    <Box spacing={5}>
      <Stack spacing={5}>
      <SingleSearchForm id='org_id' label='組織ID' placeholder='ab1234567890cd1234567890' handleClick={()=>getOrgInfo(orgId)} value={orgId} handleChange={handleChange}/>
      <br/>
      {information && <font>{information}</font>}
      {errorMessage && <font color="red">{errorMessage}</font>}
      <br/>
    {org &&
      <div>
      <Stack direction="row" spacing={2}>
        <TextField
          id="org_name"
          label="組織名" variant="outlined"
          fullWidth
          value={org.org_name || " "}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          id="airtable_client_id"
          label="AIRTABLE_ID" variant="outlined"
          fullWidth
          value={org.airtable_client_id || " "}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          id="OWNER_BCL_ID"
          label="OWNER_BCL_ID" variant="outlined"
          fullWidth
          value={org.phone || " "}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          id="OWNER_NAME"
          label="オーナ名" variant="outlined"
          fullWidth
          value={org.name || " "}
          InputProps={{
            readOnly: true,
          }}
        />
      </Stack>
      <br/>
      <Stack direction="row" spacing={2}>
      <TextField
          id="stripe_email"
          label="stripe_emai" variant="outlined"
          fullWidth
          value={org.stripe_email || " "}
          sx={{ width: 400 }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          id="stripe_customer_id"
          label="stripe_cusotmer_id" variant="outlined"
          fullWidth
          value={org.stripe_customer_id || " "}
          sx={{ width: 280  }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          id="service_units"
          label="プラン(LaaP)契約数" variant="outlined"
          fullWidth
          value={org.service_units || " "}
          sx={{ width: 180}}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          id="used_units"
          label="プラン(LaaP)利用数" variant="outlined"
          fullWidth
          value={org.bx1_used_units || " "}
          sx={{ width: 180 }}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          id="bx1_service_units"
          label="プラン(BX1)契約数" variant="outlined"
          fullWidth
          value={org.bx1_service_units || " "}
          sx={{ width: 180}}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          id="bx1_used_units"
          label="プラン(BX1)利用数" variant="outlined"
          fullWidth
          value={org.used_units || " "}
          sx={{ width: 180 }}
          InputProps={{
            readOnly: true,
          }}
        />
      </Stack><br/>
      <Stack direction="row" spacing={2}>
        <TextField
          id="device_count"
          label="デバイス数" variant="outlined"
          fullWidth
          value={org.device_count || " "}
          sx={{ width: 180 }}
          InputProps={{
            readOnly: true,
          }}
        />
      <TextField
          id="org_category"
          label="カテゴリ" variant="outlined"
          fullWidth
          value={org.org_category || " "}
          sx={{ width: 180 }}
          InputProps={{
            readOnly: true,
          }}
        />
      <TextField
          id="web_url"
          label="BOOKINGX_URL" variant="outlined"
          fullWidth
          value={org.web_url || " "}
          InputProps={{
            readOnly: true,
          }}
        />
        <TextField
          id="reason"
          label="削除フラグ" variant="outlined"
          sx={{ width: 140 }}
          value={org.del_flag}
          InputProps={{
            readOnly: true,
          }}
        />
      </Stack>
      <br/>
      <Divider />
      <br/>
      <Stack direction="row" spacing={2}>
        <TextField
        required
        id="reason"
        label="解約(取消)理由" variant="outlined"
        sx={{ width: 600 }}
        inputProps={{ maxLength: 64 }}
        placeholder="無料期間終了"
        onChange={handleChangeReason}
        />
        <TextField
        id="url"
        label="URL" variant="outlined"
        inputProps={{ maxLength: 250, pattern: "^[a-zA-Z0-9_]+$" }}
        sx={{ width: 600 }}
        placeholder="https://asana.com/XXXXX"
        onChange={handleChangeURL}
        />
      </Stack>
    <br/>
      <Stack direction="row" spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
          <DatePicker label="解約日" value={!org.unreg_time?null:dayjs(org.unreg_time)} readOnly disableFuture/>
          <DatePicker label="最終利用日" value={!org.unreg_scheduled_date?null:dayjs(org.unreg_scheduled_date)} onChange={handleChangeUnregDate} />
          <DatePicker label="最終保存日" value={!org.delete_scheduled_date?null:dayjs(org.delete_scheduled_date)} onChange={handleChangeDeleteDate} />
        </LocalizationProvider>
        <TextField
          id="reason"
          label="解約フラグ" variant="outlined"
          sx={{ width: 100 }}
          value={org.unreg_flag}
          InputProps={{
            readOnly: true,
          }}
        />
      </Stack>
    <br/>
      <Stack direction="row" spacing={2}>
      {org && <SelectConfirm
        initialValue={org.nbx_del_flag}
        apiEndpoint="/v1/org/viewportal"
        options={VIEW_OPTIONS}
        label="BOOKINGX Portal"
        org_id={orgId}
        plat_code="NewBookingx"
        callback={callbackChangeVisible}/>}
      {org && <SelectConfirm
        initialValue={org.go_del_flag}
        apiEndpoint="/v1/org/viewportal"
        options={VIEW_OPTIONS}
        label="KEYVOX Go"
        org_id={orgId}
        plat_code="GO"
        callback={callbackChangeVisible}/>}
      </Stack>
      <br/>
      <Stack direction="row" spacing={2}>
        <div>
        <Button variant="contained" onClick={() => (unregisterButton())} disabled={!unregister} >解約</Button>
        <strong>
        <Dialog open={openUnregsiterDialog} onClose={() => (closeDialog())}>
          <DialogTitle>解約確認</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {org.org_name}の解約を予約します。（毎日9:15に実行されます。）よろしいですか？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>キャンセル</Button>
            <Button onClick={unregsiterLaaP}>解約</Button>
          </DialogActions>
        </Dialog></strong>
        </div>
        <div>
        <Button variant="contained" onClick={() => (rollbackUnregisterButton()) } disabled={!rollback} >解約取消</Button>
        <strong>
        <Dialog open={openRollbackDialog} onClose={() => (closeDialog())}>
          <DialogTitle>解約取消確認</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {org.org_name}の解約を（即時）取り消します。よろしいですか？
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>キャンセル</Button>
            <Button onClick={rollbackUnregister}>実行</Button>
          </DialogActions>
        </Dialog></strong>
        </div>
        <div>
        <Button variant="contained" onClick={() => (addAirtableButton()) } disabled={!createBilling} >請求連携</Button>
        <strong>
        <Dialog open={openBillingDialog} onClose={() => (closeDialog())}>
          <DialogTitle>AirTable-Stripeの連携確認</DialogTitle>
          <DialogContent>
            <DialogContentText>
              入力したemailに該当するStripeデータが、AirtableとBACSに登録されます。<br/>
              emailを入力しない場合はStripeデータは登録されません。
              <br /><font color="red">{errorMessage}</font>
            </DialogContentText>
            <TextField
              required
              autoFocus
              margin="dense"
              id="reason"
              label="email(stripe)"
              type="text"
              fullWidth
              variant="standard"
              onChange={emailChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDialog}>キャンセル</Button>
            <Button onClick={addAirtable}>登録</Button>
          </DialogActions>
        </Dialog>
        </strong>
        </div>
        {org && <UpdateDeviceContractConfirm
        org_id={orgId}
        operator={user.name}
        service_id={SERVICE_ID.LAAP}
        disable={!changeableNumberOfLaaP}
        callback={callbackChangeContract}/>}
        {org && <UpdateDeviceContractConfirm
        org_id={orgId}
        operator={user.name}
        service_id={SERVICE_ID.BX1}
        disable={!changeableNumberOfLaaP}
        callback={callbackChangeContract}/>}
        </Stack>

    </div>
    }
    </Stack>
    </Box>
  )}
